import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import MovieDetails from './MovieDetails/MovieDetails.js';
import Home from './Home/Home.js';

function App() {
  return (    
    <div>  
      
    </div>
  );
}

export default App;
